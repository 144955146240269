/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.mychart {
  position: relative;
  text-align: center;
  font-size: 0.9em;
  padding: 16px;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
}
.mychart .title {
  color: #14837B;
  margin-bottom: 18px;
}
.mychart .mychart-content {
  position: relative;
  width: 100%;
}
.mychart .mychart-content .mychart-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mychart .mychart-content .mychart-inner .anticon {
  display: block;
  font-size: 4em;
  margin-bottom: 8px;
}
.mychart .mychart-content .empty {
  color: #8c8c8c;
}
.mychart .mychart-content .error {
  color: #f5222d;
}
.mychart.mychart-border {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.mychart.mychart-with-summary {
  padding-bottom: 28px;
}
.mychart .summary {
  position: absolute;
  bottom: 4px;
  right: 8px;
  text-align: right;
  font-size: 0.95em;
}
.legend-pane {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.legend-item {
  display: flex;
  flex-wrap: nowrap;
  font-size: 0.9em;
  margin-right: 8px;
}
.legend-item .legend-color {
  width: 26px;
  height: 14px;
  margin-right: 2px;
}
